import { Modal } from "react-bootstrap";
import "./Styles.css";
import { leaf1, leaf2 } from "../../../../components/ImgExport";
import { useEffect, useState } from "react";
import ModalLoading from "../../../../components/ModalLoading";
import { Elements } from "@stripe/react-stripe-js";
import ChangeCardForm from "./Component/ChangeCardForm";
import { loadStripe } from "@stripe/stripe-js";
import { gConfigStripeAdmin } from "../../../../api/apiAdmin/ConfigSetting";
import { getLanguageUI } from "../../../../Services/languageUI";

interface ModalChangeCardSubcriptionProps {
  show: boolean;
  bank_number: string;
  bank_date_card: string;
  bank_CVC: string;
  UserIdStore: number;
  handleClose: (status?: boolean) => void;

  // setIsOwner?: (e: boolean) => void;
}

const ModalChangeCardSubcription = ({
  show,
  handleClose,
}: // setIsOwner
ModalChangeCardSubcriptionProps) => {
  const languageUI = getLanguageUI().subscriptionPage;
  const [publishKey, setpublishKey] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);

  const gConfigStripe = async () => {
    const req = await gConfigStripeAdmin();
    if (Number(req?.status) === 1) {
      setpublishKey(req?.publishKey ?? "");
    }
  };

  useEffect(() => {
    if (show) {
      gConfigStripe();
    }
  }, [show]);
  return (
    <>
      <ModalLoading show={showLoading} onHide={() => setShowLoading(false)} />
      <Modal
        show={show}
        onHide={handleClose}
        className=" border-modal-16px modal-clock-in-out"
      >
        <Modal.Dialog className="w-100">
          <Modal.Body className="p-0">
            <div className="position-relative px-3 d-flex align-items-center flex-column">
              <div className="px-3 mt-3 z-10 position-relative">
                <div className="row">
                  <p className="text-delete-modal-customer mb-1 text-center">
                    {languageUI.changeCard}
                  </p>
                  <Elements stripe={loadStripe(publishKey)}>
                    <ChangeCardForm handleClose={handleClose} />
                  </Elements>
                </div>
              </div>
              <img src={leaf1} alt="" className="img-leaf1-otp-owner" />
              <img src={leaf2} alt="" className="img-leaf2-otp-owner" />
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalChangeCardSubcription;
