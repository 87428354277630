import { Modal } from "react-bootstrap";
import "./Styles.css";
import OtpInput from "react18-input-otp";
import { useEffect, useState } from "react";
import * as PasscodeApi from "../../../../../api/apiOwner/PasscodeApi";
import * as StripeOwnerApi from "../../../../../api/apiOwner/StripeOwnerApi";
import { trashWhite, leaf1, leaf2 } from "../../../../../components/ImgExport";
import ModalLoading from "../../../../../components/ModalLoading";
import $ from "jquery";
import { getLanguageUI } from "../../../../../Services/languageUI";

interface ModalOTPDeleteAccountProps {
  show: boolean;
  UserIdStore?: number | null;
  idAccount: string;
  handleClose: (load?: boolean) => void;
  // handleSuccess: () => void;
}

const ModalOTPDeleteAccount = ({
  show,
  UserIdStore,
  handleClose,
  idAccount = "",
}: // handleSuccess,
ModalOTPDeleteAccountProps) => {
  const languageUI = getLanguageUI().passCode;
  const [passCode, setPassCode] = useState("");
  const [messageError, setMessageError] = useState("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const handleChange = (enteredOtp: string) => {
    setPassCode(enteredOtp);
  };
  const SubmitPasscode = async () => {
    if (passCode.length !== 4) {
      setMessageError("Please enter passcode!");
    } else if (UserIdStore) {
      setShowLoading(true);
      const resStatus = await PasscodeApi.checkPasscode(UserIdStore, passCode);
      if (Number(resStatus.status) === 1) {
        Delete();
      } else {
        setShowLoading(false);
        setMessageError(resStatus?.message ?? "");
      }
    }
  };

  const Delete = async () => {
    const req = await StripeOwnerApi.DeleteAccountStripe(idAccount);
    if (Number(req?.status) === 1) {
      setShowLoading(false);
      handleClose(true);
    } else {
      setMessageError(req?.message ?? "");
      setShowLoading(false);
    }
  };
  useEffect(() => {
    const lengthInput = $(".otp input");

    if (lengthInput != null) {
      lengthInput.trigger("focus");
    }
    if (show) {
      setMessageError("");
      setPassCode("");
    }
  }, [show]);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-delete-customer modal-clock-in-out"
      >
        <Modal.Dialog>
          <Modal.Body>
            <div className="position-relative px-3 d-flex align-items-center flex-column">
              <div className="text-center">
                <p className="text-delete-modal-customer mb-1 text-center">
                  {languageUI.toDeleteEnter}
                </p>
                <p className="text-pass-code-clock">
                  {languageUI.yourPassCode}
                </p>
                <p className="text-center text-danger text-italic mb-1">
                  {messageError}
                </p>
              </div>
              <div
                className={`otp-input  mb-2 mt-2 d-flex justify-content-between w-100 px-4 ${
                  messageError !== "" ? "border-red" : ""
                }`}
              >
                <OtpInput
                  value={passCode}
                  onChange={handleChange}
                  numInputs={4}
                  isInputNum={true}
                />
              </div>
              <button
                className="btn-check-in-out d-flex flex-column justify-content-center mt-3 align-items-center bg-danger-500 border-none"
                onClick={SubmitPasscode}
              >
                <img src={trashWhite} alt="" className="mb-1" />
                {languageUI.delete}
              </button>
              <img src={leaf1} alt="" className="img-leaf1-otp-delete-table" />
              <img src={leaf2} alt="" className="img-leaf2-otp-delete-table" />
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalOTPDeleteAccount;
