import { useEffect, useState } from "react";
import "./Styles.css";
import ModalPayBillTablePOS from "../ModalPayBillTablePOS/ModalPayBillTablePOS";
import * as orderConfilmApi from "../../../../api/apiCheckin/orderConfilmApi";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import * as paymentApi from "../../../../api/apiCheckin/paymentApi";
import ModalChooseBillOrder from "../ModalChooseBillOrder/ModalChooseBillOrder";
import { useNavigate } from "react-router-dom";
import ModalPayBillCashTablePOS from "../ModalPayBillCashTablePOS/ModalPayBillCashTablePOS";
import * as orderSlice from "../../../../store/slices/orderSlice";
import * as tableSlice from "../../../../store/slices/tableSlice";
import React from "react";
import { ListPrinterByCatalog } from "../../../../api/apiOwner/SettingPrinterApi";
import { TableInfo } from "../../../../api/apiOwner/tableApi";
import ModalEnterGuest from "../ModalEnterGuest/ModalEnterGuest";
interface TableItemsProps {
  NameTable?: string;
  StatusTable?: number; //0:Availabel,1: Reserved, 2:Paid
  numberChair?: number;
  id?: string;
  listStaff?: paymentApi.TipStaff[];
  tipList?: paymentApi.Tip[];
  index?: boolean;
  is_cash: boolean;
  is_visa: boolean;
  tableShow?: string;
  orderShow?: string;
  CheckPaymentMethodOrder?: (owner_id: number, table_id: string) => void;
  ConfilmResetTable?: (table_id: string, tableName: string) => void;
  setTableSelected: (value: string) => void;
  ReloadTable: (tableOL?: tableSlice.Table[]) => void;
  tx: number;
  ty: number;
  scale: number;
  rorate: number;
  shape: string;
  showToastPrint?: (mess: string) => void;
  listPrinterName: ListPrinterByCatalog[];
  listTable: TableInfo[];
  gListFloorByOwner: () => void;
  floor_id?: string;
  refeshListTable: (tableId: number, floor_id: string) => void;
}
const TableItems: React.FC<TableItemsProps> = ({
  tx,
  ty,
  scale,
  NameTable = "",
  rorate,
  shape,
  numberChair = 0,
  id = "",
  index,
  StatusTable = 0,
  ConfilmResetTable,
  setTableSelected,
  listStaff,
  tipList,
  ReloadTable,
  CheckPaymentMethodOrder,
  is_cash = false,
  is_visa = false,
  orderShow = "",
  tableShow = "",
  showToastPrint,
  listPrinterName,
  listTable,
  gListFloorByOwner,
  floor_id,
  refeshListTable,
}) => {
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const listOrderOffline = useSelector(orderSlice.selectorOrder);
  const [showModalPayBillTable, setShowModalPayBillTable] = useState(false);
  const [showModalPayBillTableCash, setShowModalPayBillTableCash] =
    useState(false);
  const [showModaChooseBillOrder, setShowModaChooseBillOrder] = useState(false);
  const [showModalGuest, setShowModalGuest] = useState(false);
  const [OrderWait, setOrderWait] = useState<orderConfilmApi.OrderWait[]>([]);
  const UserIdStore = useSelector(userSlice.selectorUserId);

  const dismisModalGuest = () => {
    setShowModalGuest(false);
  };
  const dismisModalPayBillTable = () => {
    setShowModalPayBillTable(false);
  };
  const dismisModalPayBillTableCash = () => {
    setShowModalPayBillTableCash(false);
  };
  const dismisModaChooseBillOrder = () => {
    setShowModaChooseBillOrder(false);
  };
  const handleSuccess = (tableOL: tableSlice.Table[] = []) => {
    if (!isOnline) {
      setShowModalPayBillTable(false);
      setShowModalPayBillTableCash(false);
      ReloadTable(tableOL);
    } else {
      setShowModalPayBillTable(false);
      setShowModalPayBillTableCash(false);
      ReloadTable(tableOL);
      CheckPaymentMethodOrder?.(UserIdStore ?? 0, id);
    }
  };
  const gOrderWaitOrder = async (payment?: number) => {
    if (!isOnline) {
      if (payment === 1) {
        setOrderWait(
          listOrderOffline
            ? listOrderOffline.filter(
                (item) =>
                  item?.is_end !== 1 &&
                  item?.table_id === id &&
                  item?.payment === 1
              )
            : []
        );
      } else {
        setOrderWait(
          listOrderOffline
            ? listOrderOffline.filter(
                (item) =>
                  item?.is_end !== 1 &&
                  item?.table_id === id &&
                  item?.payment !== 1
              )
            : []
        );
      }
    } else {
      if (UserIdStore) {
        const OrderWait = await orderConfilmApi.gListOrderWaitByTable(
          UserIdStore,
          id,
          payment
        );
        if (Number(OrderWait.status) === 1) {
          setOrderWait(OrderWait?.data ?? []);
        }
      }
    }
  };

  const checkExistOrder = () => {
    if (StatusTable === 0) {
      // navigate("/web-owner/posmenu", {
      //   state: { table_id: id, NameTable, floor_id },
      // });
      setShowModalGuest(true);
    } else {
      gOrderWaitOrder(2);
      setShowModaChooseBillOrder(true);
    }
  };

  const checkStatusTable = () => {
    if (StatusTable === 0) {
      // navigate("/web-owner/posmenu", {
      //   state: { table_id: id, NameTable, floor_id },
      // });
      setShowModalGuest(true);
    }
  };

  const chooseBillHandle = (guest: number, order_id: string = "") => {
    if (order_id !== "") {
      navigate("/web-owner/posmenu", {
        state: { table_id: id, NameTable, order_id, floor_id, guest },
      });
    } else {
      navigate("/web-owner/posmenu", {
        state: { table_id: id, NameTable, floor_id, guest },
      });
    }
  };

  useEffect(() => {
    // Đăng ký sự kiện kiểm tra trạng thái kết nối mạng
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Hủy đăng ký sự kiện khi component bị hủy
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  const checkShowOrder = () => {
    if (
      tableShow !== "" &&
      orderShow !== "" &&
      Number(tableShow) === Number(id)
    ) {
      setTableSelected(id);
      if (StatusTable !== 0) {
        CheckPaymentMethodOrder?.(UserIdStore ?? 0, id);
      }
      if (StatusTable === 0) {
        checkExistOrder();
        // setShowModalGuest(true);
      }
      gOrderWaitOrder(2);
      setShowModalPayBillTable(true);
    }
  };
  const redirectOrder = (guest: number) => {
    navigate("/web-owner/posmenu", {
      state: { table_id: id, NameTable, floor_id, guest },
    });
  };

  useEffect(() => {
    checkShowOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <div
      className={`w-fit ${
        index ? "position-relative z-index-20" : ""
      } aspect-square  my-2`}
      style={{
        transform: `translate3d(${tx}px, ${ty}px, 0)`,
        minWidth: "50px",
        width: `${scale * 10}px`,
        position: "absolute",
        top: "0px",
        left: "0px",
        minHeight: "50px",
        height: `${scale * 10}px`,
      }}
    >
      <div
        className={`table-pos p-0 d-flex justify-content-center overflow-hidden align-items-center h-100 w-100 position-relative ${
          index ? "border-1px-FF7B2C z-index-20" : ""
        }  ${
          shape === "circle" ? "aspect-square border-circle" : "border-12px"
        }`}
        style={{ rotate: `${rorate}deg` }}
        onClick={() => {
          setTableSelected(id);
          if (StatusTable !== 0) {
            CheckPaymentMethodOrder?.(UserIdStore ?? 0, id);
            if (StatusTable === 1) {
              gOrderWaitOrder(2);
              setShowModalPayBillTable(true);
            }
          }
          if (StatusTable === 0) {
            checkExistOrder();
          }
        }}
      >
        <div className="w-100 h-100 d-flex" id={id} onClick={checkStatusTable}>
          {/* <div
            className={`w-100 h-100 bg-primary ${
              StatusTable === 0 ? "d-none" : ""
            }`}
            onClick={checkExistOrder}
          ></div> */}
          {StatusTable !== 0 && (
            <div
              style={{ backgroundColor: "#f1735d" }}
              className={`w-100 flex-shrink-0 h-100 ${
                StatusTable === 1 ? "" : "d-none"
              } `}
              // onClick={() => {
              //   gOrderWaitOrder(2);
              //   setShowModalPayBillTable(true);
              // }}
            >
              {/* <PointOfSaleRoundedIcon className="text-white" /> */}
            </div>
          )}
        </div>
        <div
          className={`circle-name-table position-absolute  ${
            StatusTable === 0
              ? "available"
              : StatusTable === 1
              ? "reserved"
              : "paid"
          }`}
          style={{ rotate: `${-rorate}deg` }}
        >
          <p className="mb-0 text-name-table">{NameTable}</p>
          {/* <div
            className={`btn-option-pay-cash h-fit ${
              shape === "square" ? "" : "btn-pos-table-position-top"
            }`}
            style={{ rotate: `${-rorate}deg` }}
          >
            {is_cash && (
              <div
                className={`btn-paybill-cash-table ${index ? "" : "d-none"} ${
                  StatusTable === 1 ? "" : "d-none"
                } `}
                onClick={() => {
                  gOrderWaitOrder(1);
                  setShowModalPayBillTableCash(true);
                }}
              >
                <PriceChangeRoundedIcon className="text-white" />
              </div>
            )}
          </div>
          <div
            className={`btn-option-order ${
              shape === "square" ? "" : "btn-pos-table-position-top"
            }`}
          >
            <div
              className={`btn-order-table-reserved  ${
                StatusTable !== 0 ? "" : "d-none"
              } ${StatusTable === 1 ? "" : "d-none"} `}
              onClick={() => {
                checkExistOrder();
              }}
            >
              <BorderColorOutlinedIcon className="text-white" />
            </div>
          </div>
          <div
            className={`btn-option-table ${
              shape === "square" ? "" : "btn-pos-table-position-top"
            }`}
          >
            <div
              className={`btn-clean-table ${shape === "square" ? "" : ""} ${
                index ? "" : "d-none"
              } ${StatusTable === 2 ? "" : "d-none"} `}
              onClick={() => ConfilmResetTable?.(id, NameTable)}
            >
              <CleaningServicesIcon className="text-white" />
            </div>
            <div
              className={`btn-order-table ${index ? "" : "d-none"} ${
                StatusTable === 0 ? "" : "d-none"
              } `}
              onClick={() => {
                checkExistOrder();
              }}
            >
              <BorderColorOutlinedIcon className="text-white" />
            </div>
            {StatusTable !== 0 && (
              <div
                className={`btn-paybill-table ${
                  StatusTable !== 0 ? "" : "d-none"
                } ${StatusTable === 1 ? "" : "d-none"} `}
                onClick={() => {
                  gOrderWaitOrder(2);
                  setShowModalPayBillTable(true);
                }}
              >
                <PointOfSaleRoundedIcon className="text-white" />
              </div>
            )}
          </div> */}
        </div>
      </div>
      <ModalPayBillTablePOS
        listPrinterName={listPrinterName}
        handleSuccess={handleSuccess}
        handleClose={dismisModalPayBillTable}
        show={showModalPayBillTable}
        OrderWait={OrderWait}
        table_name={NameTable}
        table_id={id}
        tipList={tipList}
        listStaff={listStaff}
        handlePlitBill={gOrderWaitOrder}
        showToastPrint={showToastPrint}
        listTable={listTable}
        gListFloorByOwner={gListFloorByOwner}
        chooseBillHandle={chooseBillHandle}
        handleMoveBillSuccess={refeshListTable}
        floor_id={floor_id ?? ""}
      />
      <ModalChooseBillOrder
        handleClose={dismisModaChooseBillOrder}
        show={showModaChooseBillOrder}
        chooseBillHandle={chooseBillHandle}
        OrderWait={OrderWait}
      />
      <ModalPayBillCashTablePOS
        listPrinterName={listPrinterName}
        handleSuccess={handleSuccess}
        handleClose={dismisModalPayBillTableCash}
        show={showModalPayBillTableCash}
        OrderWait={OrderWait}
        table_name={NameTable}
        table_id={id}
        tipList={tipList}
        listStaff={listStaff}
        handlePlitBill={gOrderWaitOrder}
        showToastPrint={showToastPrint}
      />
      <ModalEnterGuest
        handleClose={dismisModalGuest}
        show={showModalGuest}
        redirectOrder={redirectOrder}
      />
    </div>
  );
};

export default TableItems;
