import * as Request from "../Request";

export interface InfoFood {
  id: string;
  service_id: string;
  service_item_code: string;
  name: string;
  price: string;
  discount: string;
  imageCourse?: string;
  donvitinh?: string;
  side_id?: string;
  max_side?: string;
  typeFood?: number;
}
export interface InfoFoodOffline {
  id: string;
  service_id: string;
  service_item_code: string;
  name: string;
  price: string;
  discount: string;
  imageCourse?: string;
  donvitinh?: string;
  side_id?: string;
  max_side?: string;
  NoteFood?: NoteFood[];
  Side_Dishes?: Side_Dishes[];
}
export interface NoteFood {
  notefood_id?: string;
  notefood_name?: string;
}
export interface Side_Dishes {
  id?: string;
  name?: string;
  price?: string;
}
export interface NoteOptionChoose {
  id?: string;
  price?: string;
  option_name?: string;
}
export interface listNoteOptionFood {
  id?: string;
  food_id?: string;
  option_name?: string;
  price?: string;
}
export interface Data {
  InfoFood?: InfoFood;
  NoteFood?: NoteFood[];
  Side_Dishes?: Side_Dishes[];
  listNoteOptionFood: listNoteOptionFood[];
}
export interface Result<T> {
  status: number;
  message?: string;
  data?: T;
}

export interface FoodCategory {
  id?: string;
  owner_id?: string;
  service_code?: string;
  name?: string;
  role?: string;
  date_begin?: string;
  date_end?: string;
  img?: string;
  discount?: string;
}

export interface Menu {
  description?: string;
  imageCourse?: string;
  menu_id?: string;
  menu_name?: string;
  parent_id?: string;
  price?: string;
  side_id?: string;
}

export interface MenuCategory {
  foodgroup_id?: string;
  foodgroup_name?: string;
  owner_id?: string;
  parent_id?: string;
  list_menu?: Menu[];
  color?: string;
  price_old?: string;
}
export interface ResultMenu {
  status: number;
  message?: string;
  data?: MenuCategory[];
}

export interface ResultCategory {
  status: number;
  message?: string;
  data?: FoodCategory[];
}

export const gInfoFood = async (id: string) => {
  return await Request.fetchAPI(`checkin/gDetailFood.php`, "POST", { id })
    .then((res: Result<Data>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const gListInfoFoodOffline = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gDetailFoodOffline.php`, "POST", {
    owner_id,
  })
    .then((res: Result<InfoFoodOffline[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};

export const gFoodCateGory = async (ownerID: string) => {
  return await Request.fetchAPI(
    `/checkin/gListFoodGroup.php?owner_id=${ownerID}`,
    "get"
  )
    .then((res: ResultCategory) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gListMenu = async (
  owner_id: number,
  searchFoodGroup: string = "",
  searchFood: string = "",
  clear: boolean = false
) => {
  return await Request.fetchAPI(
    `/checkin/gListMenu.php?owner_id=${owner_id}&foodgroup_id=${
      clear ? "" : searchFoodGroup
    }&food_name=${searchFood}`,
    "get"
  )
    .then((res: ResultMenu) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gListNoteOptionByFood = async (
  food_id: number,
  optionNoteFoodChoose: NoteOptionChoose[]
) => {
  return await Request.fetchAPI(`checkin/gListNoteOptionFoods.php`, "POST", {
    food_id,
    optionNoteFoodChoose: JSON.stringify(optionNoteFoodChoose),
  })
    .then((res: Result<listNoteOptionFood[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
