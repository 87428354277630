import "./styles.css";
interface NoteFoodItemProps {
  noteFoodItem: string;
}
const NoteFoodItem = ({ noteFoodItem }: NoteFoodItemProps) => {
  return (
    <div className="food-note-item mt-2">
      {noteFoodItem}
      {/* <CancelRoundedIcon className="icon-remove-note text-red" /> */}
    </div>
  );
};
export default NoteFoodItem;
