import "./Styles.css";
import { Modal } from "react-bootstrap";
import React from "react";
import { errorPayemnt } from "../../../../components/Image";

interface ModalPaymentErrorProps {
  show: boolean;
  handleClose: () => void;
  content?: string;

  // setIsOwner?: (e: boolean) => void;
}

const ModalPaymentError = ({
  show,
  handleClose,
  content,
}: ModalPaymentErrorProps) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className=" border-modal-16px modal-clock-in-out modal-payment-err box-body-err"
      >
        <Modal.Dialog className="w-100 h-100 box-body-err m-0 p-0 overflow-hidden">
          <Modal.Body className="p-0  h-100">
            <div className="d-flex flex-column align-items-center ">
              <p className="text-delete-modal-customer mb-1 text-center mt-2">
                Payment Error
              </p>
              <img src={errorPayemnt} alt="" className="h-100px w-100px " />
              <p className="text-content-payment-err mb-1 text-center mt-2">
                {content}
              </p>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalPaymentError;
