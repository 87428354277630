import React from "react";
import "./styles.css";
import { useEffect, useState } from "react";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "@mui/material";
import { Transition } from "react-transition-group";

interface ModalAddToCardProps {
  show: boolean;
  handleClose: () => void;
  submit: () => void;
}

const ModalEnterGuest = ({
  show,
  handleClose,
  submit,
}: ModalAddToCardProps) => {
  return (
    <>
      <Transition in={show} timeout={400}>
        {(state: string) => (
          <Modal
            className="mui-add-to-card"
            keepMounted
            open={!["exited", "exiting"].includes(state)}
            onClose={handleClose}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: "none",
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: "blur(8px)" },
                    entered: { opacity: 1, backdropFilter: "blur(8px)" },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === "exited" ? "hidden" : "visible",
            }}
          >
            <ModalDialog
              layout="center"
              size="lg"
              sx={{
                opacity: 0,
                transition: `opacity 300ms`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <DialogTitle>
                <div className="w-100">
                  <div
                    className="d-flex justify-content-end w-100"
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                  <div className="d-flex w-100 justify-content-center align-items-center mt-1">
                    <p className="title-filter text-2xl mb-0 font-bold pe-2">
                      Enter number of guest?
                    </p>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                <div className="px-3 overflow-y-scroll">
                  <p className="text-center">
                    Please let us know the total number of people in your group
                    so we can better arrange food preparation time to serve you
                    efficiently.
                  </p>
                  <div className="d-flex justify-content-center w-100">
                    <input
                      type="number"
                      className="px-3 text-4a4a6a font-medium w-150px border-999999 rounded-3"
                    />
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <button
                  className="custom-filter-btn border-none w-100 text-ffffff"
                  onClick={submit}
                >
                  Submit
                </button>
              </DialogActions>
            </ModalDialog>
          </Modal>
        )}
      </Transition>
    </>
  );
};

export default ModalEnterGuest;
