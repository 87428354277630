import { Modal } from "react-bootstrap";
import "./Styles.css";
import { leaf1, leaf2, trashWhite } from "../../../../components/ImgExport";
import OtpInput from "react18-input-otp";
import { useEffect, useState } from "react";
import * as PasscodeApi from "../../../../api/apiOwner/PasscodeApi";
import * as userSlice from "../../../../store/slices/userSlice";
import * as InventoryApi from "../../../../api/apiOwner/InventoryApi";
import { useSelector } from "react-redux";
import ModalLoading from "../../../../components/ModalLoading";
import $ from "jquery";
import { getLanguageUI } from "../../../../Services/languageUI";
import BackspaceIcon from "@mui/icons-material/Backspace";

interface ModalOTPDeleteInventoryItemsProps {
  show: boolean;
  // UserIdStore?: number | null;
  handleClose: () => void;
  // handleSuccess: () => void;
  id?: string;
}

const ModalOTPDeleteInventoryItems = ({
  show,
  id = "",
  // UserIdStore,
  handleClose,
}: // handleSuccess,
ModalOTPDeleteInventoryItemsProps) => {
  const languageUI = getLanguageUI().passCode;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [passCode, setPassCode] = useState("");
  const [messageError, setMessageError] = useState("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const handleChange = (enteredOtp: string) => {
    setPassCode(enteredOtp);
  };
  const SubmitPasscode = async () => {
    if (passCode.length !== 4) {
      setMessageError("Please enter passcode!");
    } else if (!id || id === "") {
      setMessageError("Material not found!");
    } else if (UserIdStore) {
      const resStatus = await PasscodeApi.checkPasscode(UserIdStore, passCode);
      if (Number(resStatus.status) === 1) {
        setMessageError("");
        DeleteMaterial();
      } else {
        setMessageError(resStatus?.message ?? "");
      }
    }
  };
  const DeleteMaterial = async () => {
    setShowLoading(true);
    const req = await InventoryApi.DelMaterial(id);
    if (Number(req?.status) === 1) {
      handleClose();
      setShowLoading(false);
    } else {
      setMessageError(req?.message ?? "fail");
      setShowLoading(false);
    }
  };
  const renderElementPasscode = () => {
    const items = [];
    for (let i = 1; i < 10; i++) {
      items.push(
        <button
          className="keyboard-passcode"
          onClick={() => handleEnterPasscode(String(i))}
        >
          {i}
        </button>
      );
    }
    return items;
  };

  const handleEnterPasscode = (value: string) => {
    if (passCode.length < 4) {
      setPassCode(passCode + value);
    }
  };
  const deletePasscode = () => {
    setPassCode(passCode.slice(0, -1));
  };
  const deleteAllPasscode = () => {
    setPassCode("");
  };
  useEffect(() => {
    const lengthInput = $(".otp input");

    if (lengthInput != null) {
      lengthInput.trigger("focus");
    }
    if (show) {
      setMessageError("");
      setPassCode("");
    }
  }, [show]);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-delete-customer modal-clock-in-out"
      >
        <Modal.Dialog className="h-100 w-100 m-0">
          <Modal.Body className="h-100 w-100 m-0">
            <div className="position-relative px-3 d-flex align-items-center flex-column">
              <div className="text-center">
                <p className="text-delete-modal-customer mb-1 text-center">
                  {languageUI.toDeleteEnter}
                </p>
                <p className="text-pass-code-clock">
                  {languageUI.yourPassCode}
                </p>
                <p className="text-center text-danger text-italic mb-1">
                  {messageError}
                </p>
              </div>
              <div
                className={`otp-input  mb-2 mt-2 d-flex justify-content-between w-100 px-4  position-relative z-10${
                  messageError !== "" ? "border-red" : ""
                }`}
              >
                <OtpInput
                  value={passCode}
                  onChange={handleChange}
                  numInputs={4}
                  isInputNum={true}
                  className="otp"
                  isDisabled
                />
              </div>
              <div className="d-flex gap-4 align-items-center">
                <div className="box-keyboard-passcode">
                  {renderElementPasscode()}
                  <button
                    className="keyboard-passcode"
                    onClick={deleteAllPasscode}
                  >
                    C
                  </button>
                  <button
                    className="keyboard-passcode"
                    onClick={() => handleEnterPasscode(String(0))}
                  >
                    0
                  </button>
                  <button
                    className="keyboard-passcode"
                    onClick={deletePasscode}
                  >
                    <BackspaceIcon />
                  </button>
                </div>
                <button
                  className="btn-check-in-out d-flex flex-column justify-content-center mt-3 align-items-center bg-danger-500 border-none"
                  onClick={SubmitPasscode}
                >
                  <img src={trashWhite} alt="" className="mb-1" />
                  {languageUI.delete}
                </button>
              </div>
              <img
                src={leaf1}
                alt=""
                className="img-leaf1-otp-delete-inventory"
              />
              <img
                src={leaf2}
                alt=""
                className="img-leaf2-otp-delete-inventory"
              />
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalOTPDeleteInventoryItems;
