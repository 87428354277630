import { Form, Modal } from "react-bootstrap";
import "./styles.css";
import FoodBillItem from "./component/FoodBillItem/FoodBillItem";
// import SplitBill from "../ModalSplitBillDragDrop/SplitBill";
import {
  InfoOrder,
  OrderWait,
  splitBill,
} from "../../../../api/apiCheckin/orderConfilmApi";
import { FormatDolla } from "../../../../utils/format";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import * as orderConfilmApi from "../../../../api/apiCheckin/orderConfilmApi";
import { ListPrinterByCatalog } from "../../../../api/apiOwner/SettingPrinterApi";
import { PrintOrderPage } from "../../../../api/apiOwner/printerApi";
import { getLanguageUI } from "../../../../Services/languageUI";
interface ModalVoidBillProps {
  show: boolean;
  tableName: string;

  orderInfo?: OrderWait;
  handleClose: () => void;
  handleSuccess: (id: string, status: number) => void;
  setShowLoading: (status: boolean) => void;
  listPrinterName: ListPrinterByCatalog[];
}
const ModalVoidBill = ({
  handleClose,
  orderInfo,
  tableName,
  show,
  setShowLoading,
  handleSuccess,
  listPrinterName,
}: ModalVoidBillProps) => {
  const LanguageUI = getLanguageUI().posTable;
  const UserIdStore = useSelector(userSlice.selectorUserId) ?? 0;
  const [listFoodSelected, setListFoodSelected] = useState<
    orderConfilmApi.InfoOrder[]
  >([]);
  const [message, setMessage] = useState<string>("");

  const handleChooded = (status: boolean, itemOrder: InfoOrder) => {
    if (status && itemOrder?.id !== "") {
      setListFoodSelected([...listFoodSelected, itemOrder]);
    } else {
      setListFoodSelected(
        listFoodSelected.filter((item) => item?.id !== itemOrder?.id)
      );
    }
  };

  const PrintCard = async () => {
    // console.log(rawhtml);
    // console.log(listCartFood);

    if (listPrinterName.length > 0) {
      listPrinterName.map(async (itempr) => {
        if (
          itempr?.printType.some((pr) =>
            listFoodSelected.some((iItem) => iItem.typeFood === pr)
          )
        ) {
          const rawhtml = `
          <!DOCTYPE html>
          <html lang="en">

          <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Hóa đơn</title>
          <style type="text/css">
            .name-food {
              font-weight: 600;
            }

            .quanlity-number {
              font-weight: 600;
            }

            p, h6, h4 {
              margin-bottom: 0;
              margin-top: 0;
              line-height: normal;
            }

            .fw-normal{
               !important;
            }
          </style>

          </head>

          <body>

          <div id="bill" style="width: 100%; padding-right: 45px; padding-bottom: 80px">
             <h3 style="width: 100%; margin:  auto; text-align: center">Void Bill</h3>
             <div style="width: 100%;">
              <h5 class="" style="font-weight: 400; margin: 0; text-align: start;">Table name: ${tableName} - Order#${
            orderInfo?.order_id
          }
      </h5>
            </div>
            <div style="width: 100%;">

                   ${listFoodSelected
                     .map((item, i) =>
                       itempr?.printType?.some((ty) => ty === item.typeFood)
                         ? `
              <div style="display: table; width: 100%">
           <div style = "display: table-row; padding-top: 10px;  padding-right: 10px; width: 100%" >

                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                <h6 class="fw-normal" style=" margin: 0; text-align: start;">${
                  i + 1
                }.${item?.name}</h6>
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                <h6 class="fw-normal quanlity-number; margin-top: 10px !important ">x${Number(
                  item?.quantity
                )}</h6>
                 
                </div>
              </div >
              </div >

              `
                         : ``
                     )
                     .join(" ")}
            </div>
          </div >
          </body >
          </html >
  `;
          const req = await PrintOrderPage(
            UserIdStore ?? 0,
            itempr.printer_name,
            rawhtml.toString()
          );
          if (!req?.status) {
            // setMessageError(req?.message ?? ("Error printing on " + item.printer_name))
            // setShowToastError(true)
            // showToastPrint?.(req?.message ?? ("Error printing on " + item.printer_name))
          }
        }
      });
    } else {
      // setMessageError("Please configure the printer in settings!");
      // setshowModalAlertFail(true);
    }
    // console.log(text);
    // console.log(rawhtml);

    // await printJS({

    //   printable: rawhtml,
    //   targetStyles: ["*"],
    //   targetStyle: ["*"],
    //   frameId: "cardprint",
    //   type: "raw-html",
    //   showModal: false,
    //   css: ["./Styles.css"],
    //   style: ".abc {font-size: 30px; color: red;}",
    //   header: rawhtml,
    //   onError: async (error: any) => {
    //     console.error("Error Printing: ", error);
    //   },
    //   // silent: false,3
    //   onPrintDialogClose: async () => {
    //     console.info("Print Window Closed");

    //   }
    // });
    // setTimeout(() => {
    //   const arr = listCartFood ?? [];
    //   printCardInfo(arr, arr.length - 1, arr[arr.length - 1]?.food_id, arr[arr.length - 2]?.food_id ?? '', arr[arr.length - 1], Number(table_id) > 0 ? NameTable : '', orderid ?? '', '')
    // }, 1000);
  };

  const SubmitSplit = async () => {
    if (listFoodSelected.length === 0) {
      setMessage("Please select the dish you want to split the bill");
    } else {
      const listProc: string[] = listFoodSelected.map((ite) => {
        return ite?.id ?? "";
      });
      setShowLoading(true);
      const req = await orderConfilmApi.voidBill(
        UserIdStore,
        orderInfo?.order_id ?? "",
        listProc
      );
      if (req?.status !== 0) {
        handleSuccess(orderInfo?.order_id ?? "", req?.status);
        PrintCard();
      } else {
        setMessage(req?.message);
        setShowLoading(false);
      }
    }
  };
  useEffect(() => {
    if (show) {
      if (orderInfo?.listFood.length === 1) {
        setListFoodSelected(orderInfo?.listFood);
      } else {
        setListFoodSelected([]);
      }
    }
  }, [show]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      className="modal-add-printer"
    >
      <Modal.Body>
        <h1 className="text-center">{LanguageUI.voidBill}</h1>
        <div className="px-10">
          <div className=" w-100 p-4">
            <p className="mb-1 text-bill-pay-bill">
              Bill-#{orderInfo?.order_id}
            </p>
            <div className="px-3">
              <div className="rounded-5 border p-4  ">
                <Form className="d-flex flex-column gap-2">
                  {orderInfo &&
                    orderInfo?.listFood &&
                    orderInfo?.listFood.map(
                      (item, i) =>
                        item?.id && (
                          <Form.Check
                            key={i}
                            className="d-flex p-0 form-check-void-bill"
                            size={100}
                            inline
                            label={
                              <div className="d-flex justify-content-between w-100 ">
                                <p className="mb-0 font-bold ">{`${i + 1}. ${
                                  item?.name
                                }`}</p>
                                <p className="mb-0 text-black font-bold">{`${
                                  item?.quantity
                                }x ${FormatDolla(Number(item?.price))}`}</p>
                              </div>
                            }
                            name="split-bill"
                            type={"checkbox"}
                            id={`split-bill-${i}`}
                            checked={listFoodSelected.some(
                              (it) => it?.id === item?.id
                            )}
                            onClick={(e: any) => {
                              handleChooded(e?.target?.checked, item);
                            }}
                          />
                        )
                    )}
                </Form>
              </div>
            </div>
          </div>
        </div>
        {message && (
          <div className="text-danger text-italic text-center">{message}</div>
        )}
        <div className="mt-2 flex justify-content-between mt-4 px-5">
          <div
            className="text-white font-bold h-40px w-48  w-fit d-flex justify-content-center align-items-center  bg-delete-light rounded-3"
            onClick={handleClose}
          >
            Cancel
          </div>
          <div
            className="text-white font-bold h-40px w-48  w-fit d-flex justify-content-center align-items-center rounded-3 bg-primary"
            onClick={SubmitSplit}
          >
            Ok
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModalVoidBill;
